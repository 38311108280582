<template>
  <b-row>
    <b-col cols="12">
      <div class="auth-wrapper auth-v1 vrayoRegister px-2">
        <div class="auth-inner py-2">
          <!-- Register v1 -->
          <b-card class="mb-0">
            <b-link class="brand-logo">
              <vuexy-logo />
              <h2 class="brand-text text-primary ml-1">
                Vuexy
              </h2>
            </b-link>

            <b-card-title class="mb-1">
              ¡Bienvenid@ a Rayo Partners! 🚀
            </b-card-title>
            <b-card-text class="mb-2">
              Make your app management easy and fun!
            </b-card-text>

            <form-wizard
              color="#7367F0"
              :title="null"
              :subtitle="null"
              layout="vertical"
              finish-button-text="Submit"
              steps-transparent
              back-button-text="Previous"
              class="wizard-vertical mb-3"
              @on-complete="formSubmitted"
            >
              <!-- account datails tab -->
              <tab-content title="Account Details">
                <b-row>
                  <b-col
                    cols="12"
                    class="mb-2"
                  >
                    <h5 class="mb-0">
                      Account Details
                    </h5>
                    <small class="text-muted"> Enter Your Account Details. </small>
                  </b-col>
                  <b-col md="6">
                    <b-form-group
                      label="Username"
                      label-for="v-username"
                    >
                      <b-form-input
                        id="v-username"
                        placeholder="johndoe"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group
                      label="Email"
                      label-for="v-email"
                    >
                      <b-form-input
                        id="v-email"
                        type="email"
                        placeholder="john.doe@email.com"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group
                      label="Password"
                      label-for="v-password"
                    >
                      <b-form-input
                        id="v-password"
                        type="password"
                        placeholder="Password"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group
                      label="Confirm Password"
                      label-for="v-c-password"
                    >
                      <b-form-input
                        id="v-c-password"
                        type="password"
                        placeholder="Re-type Password"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </tab-content>

              <!-- personal info tab -->
              <tab-content title="Personal Info">
                <b-row>
                  <b-col
                    cols="12"
                    class="mb-2"
                  >
                    <h5 class="mb-0">
                      Personal Info
                    </h5>
                    <small class="text-muted">Enter Your Personal Info.</small>
                  </b-col>
                  <b-col md="6">
                    <b-form-group
                      label="First Name"
                      label-for="v-first-name"
                    >
                      <b-form-input
                        id="v-first-name"
                        placeholder="John"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group
                      label="Last Name"
                      label-for="v-last-name"
                    >
                      <b-form-input
                        id="v-last-name"
                        placeholder="Doe"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group
                      label="Country"
                      label-for="v-country"
                    >
                      <v-select
                        id="v-country"
                        v-model="selectedContry"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="countryName"
                        :selectable="
                          (option) => !option.value.includes('select_value')
                        "
                        label="text"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group
                      label-for="v-language"
                      label="Language"
                    >
                      <v-select
                        id="Language"
                        v-model="selectedLanguage"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :selectable="
                          (option) => !option.value.includes('select_value')
                        "
                        :options="languageName"
                        label="text"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </tab-content>

              <!-- address -->
              <tab-content title="Address">
                <b-row>
                  <b-col
                    cols="12"
                    class="mb-2"
                  >
                    <h5 class="mb-0">
                      Address
                    </h5>
                    <small class="text-muted">Enter Your Address.</small>
                  </b-col>
                  <b-col md="6">
                    <b-form-group
                      label="Address"
                      label-for="v-address"
                    >
                      <b-form-input
                        id="v-address"
                        placeholder="98 Borough bridge Road, Birmingham"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group
                      label="Landmark"
                      label-for="v-landmark"
                    >
                      <b-form-input
                        id="v-landmark"
                        placeholder="Borough bridge"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group
                      label="Pincode"
                      label-for="v-pincode"
                    >
                      <b-form-input
                        id="v-pincode"
                        placeholder="658921"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group
                      label="City"
                      label-for="v-city"
                    >
                      <b-form-input
                        id="v-city"
                        placeholder="Birmingham"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </tab-content>

              <!-- social link -->
              <tab-content title="Social Links">
                <b-row>
                  <b-col
                    cols="12"
                    class="mb-2"
                  >
                    <h5 class="mb-0">
                      Social Links
                    </h5>
                    <small class="text-muted">Enter Your Social Links</small>
                  </b-col>
                  <b-col md="6">
                    <b-form-group
                      label="Twitter"
                      label-for="v-twitter"
                    >
                      <b-form-input
                        id="v-twitter"
                        placeholder="https://twitter.com/abc"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group
                      label="Facebook"
                      label-for="v-facebook"
                    >
                      <b-form-input
                        id="v-facebook"
                        placeholder="https://facebook.com/abc"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group
                      label="Google+"
                      label-for="v-google-plus"
                    >
                      <b-form-input
                        id="v-google-plus"
                        placeholder="https://plus.google.com/abc"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group
                      label="LinkedIn"
                      label-for="v-linked-in"
                    >
                      <b-form-input
                        id="v-linked-in"
                        placeholder="https://linkedin.com/abc"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </tab-content>
            </form-wizard>

            <!-- form -->
            <validation-observer ref="registerForm">
              <b-form
                class="auth-register-form mt-2"
                @submit.prevent="validationForm"
              >
                <!-- username -->
                <b-form-group
                  label="Username"
                  label-for="username"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Username"
                    rules="required"
                  >
                    <b-form-input
                      id="username"
                      v-model="username"
                      :state="errors.length > 0 ? false : null"
                      name="register-username"
                      placeholder="johndoe"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- email -->
                <b-form-group
                  label="Email"
                  label-for="email"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Email"
                    rules="required|email"
                  >
                    <b-form-input
                      id="email"
                      v-model="regEmail"
                      :state="errors.length > 0 ? false : null"
                      name="register-email"
                      placeholder="john@example.com"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- password -->
                <b-form-group
                  label="Password"
                  label-for="password"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Password"
                    rules="required"
                  >
                    <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid' : null"
                    >
                      <b-form-input
                        id="password"
                        v-model="password"
                        :type="passwordFieldType"
                        :state="errors.length > 0 ? false : null"
                        class="form-control-merge"
                        name="register-password"
                        placeholder="············"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          :icon="passwordToggleIcon"
                          class="cursor-pointer"
                          @click="togglePasswordVisibility"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- checkbox -->
                <b-form-group>
                  <b-form-checkbox
                    id="register-privacy-policy"
                    v-model="status"
                    name="checkbox-1"
                  >
                    I agree to
                    <b-link>privacy policy & terms</b-link>
                  </b-form-checkbox>
                </b-form-group>

                <!-- submit button -->
                <b-button
                  variant="primary"
                  block
                  type="submit"
                >
                  Sign up
                </b-button>
              </b-form>
            </validation-observer>

            <b-card-text class="text-center mt-2">
              <span>Already have an account? </span>
              <b-link :to="{ name: 'auth-login-v1' }">
                <span>Sign in instead</span>
              </b-link>
            </b-card-text>

            <div class="divider my-2">
              <div class="divider-text">
                or
              </div>
            </div>

            <!-- social buttons -->
            <div class="auth-footer-btn d-flex justify-content-center">
              <b-button
                variant="facebook"
                href="javascript:void(0)"
              >
                <feather-icon icon="FacebookIcon" />
              </b-button>
              <b-button
                variant="twitter"
                href="javascript:void(0)"
              >
                <feather-icon icon="TwitterIcon" />
              </b-button>
              <b-button
                variant="google"
                href="javascript:void(0)"
              >
                <feather-icon icon="MailIcon" />
              </b-button>
              <b-button
                variant="github"
                href="javascript:void(0)"
              >
                <feather-icon icon="GithubIcon" />
              </b-button>
            </div>
          </b-card>
          <!-- /Register v1 -->
        </div>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import vSelect from 'vue-select'
import {
  // eslint-disable-next-line no-unused-vars
  BRow,
  // eslint-disable-next-line no-unused-vars
  BCol,
  BCard,
  BLink,
  BCardTitle,
  BCardText,
  BForm,
  BButton,
  BFormInput,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BFormCheckbox,
} from 'bootstrap-vue'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import { FormWizard, TabContent } from 'vue-form-wizard'

import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { codeIconInfo } from './code'

export default {
  components: {
    FormWizard,
    TabContent,
    vSelect,
    VuexyLogo,
    // BSV
    BCard,
    BLink,
    BCardTitle,
    BCardText,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormInput,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    // validations
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      selectedContry: 'select_value',
      selectedLanguage: 'nothing_selected',
      codeIconInfo,
      countryName: [
        { value: 'select_value', text: 'Select Value' },
        { value: 'Russia', text: 'Russia' },
        { value: 'Canada', text: 'Canada' },
        { value: 'China', text: 'China' },
        { value: 'United States', text: 'United States' },
        { value: 'Brazil', text: 'Brazil' },
        { value: 'Australia', text: 'Australia' },
        { value: 'India', text: 'India' },
      ],
      languageName: [
        { value: 'nothing_selected', text: 'Nothing Selected' },
        { value: 'English', text: 'English' },
        { value: 'Chinese', text: 'Mandarin Chinese' },
        { value: 'Hindi', text: 'Hindi' },
        { value: 'Spanish', text: 'Spanish' },
        { value: 'Arabic', text: 'Arabic' },
        { value: 'Malay', text: 'Malay' },
        { value: 'Russian', text: 'Russian' },
      ],
      regEmail: '',
      username: '',
      password: '',
      status: '',

      // validation rules
      required,
      email,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    formSubmitted() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Form Submitted',
          icon: 'EditIcon',
          variant: 'success',
        },
      })
    },
    validationForm() {
      this.$refs.registerForm.validate().then(success => {
        if (success) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Form Submitted',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>
